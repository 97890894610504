import { render, staticRenderFns } from "./Negara.vue?vue&type=template&id=28f5f780&scoped=true"
import script from "./Negara.vue?vue&type=script&lang=js"
export * from "./Negara.vue?vue&type=script&lang=js"
import style0 from "./Negara.vue?vue&type=style&index=0&id=28f5f780&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6_amhcwhcsvqr2myze6kddhh7yxm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28f5f780",
  null
  
)

export default component.exports